.cdk-overlay-pane .menu-single *:focus { outline: none; }

.menu-trigger {
  border: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: var(--radius-1);
}

// TODO menu max-height can be dynamic
.menu-content {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-height: 300px;
  overflow: hidden;
  border: 1px solid var(--border);
}

.menu-content-inner {
  overflow: auto;
}

.menu-trigger fa-icon {
  padding: 0 var(--spacing-3);
}

.menu-item {
  padding: var(--spacing-2) var(--spacing-3);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-1);
}

.menu-item.selected > div ,
.menu-item.selected > fa-icon {
  color: var(--primary-color);
  font-weight: var(--font-weight-3);
}

.menu-content .menu-item:hover {
  background-color: var(--hover);
}

.menu-trigger.opened-above {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow:
    0 -3px 6px rgba(0, 0, 0, 0.16),
    0 -3px 6px rgba(0, 0, 0, 0.23);
}

.menu-trigger.opened-below {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.menu-content.opened-below  {
  border-top: none;
  border-bottom-left-radius: var(--radius-1);
  border-bottom-right-radius: var(--radius-1);
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.menu-content.opened-above {
  border-bottom: none;
  border-top-left-radius:  var(--radius-1);
  border-top-right-radius:  var(--radius-1);
  box-shadow:
    0 -3px 6px rgba(0, 0, 0, 0.16),
    0 -3px 6px rgba(0, 0, 0, 0.23);
}

.menu-trigger.disabled {
  color: var(--grey);
  cursor: progress;
}

.menu-item.disabled {
  color: var(--grey);
  cursor: progress;
}
