.mat-menu-panel {
  max-width: 400px !important;
  max-height: 400px !important;
}

.mat-menu-content,
.mat-dialog-container {
  padding: 0 !important;
}

.mat-menu-panel {
  min-height: 0 !important;
}

.mat-tooltip {
  font-size: 0.9em;
}

.mat-menu-item,
.mat-menu-item-submenu-trigger {
  line-height: 30px !important;
  height: 30px !important;
  color: #333 !important;
  font-size: 1em !important;
  font-family: 'Montserrat', sans-serif !important;
}

.mat-tab-group,
.mat-tab-body-wrapper {
  height: 100% !important;
}

.mat-select-placeholder {
  font-size: 0.9em;
}

.mat-mdc-tab-body-wrapper {
  height: 100% !important;
}
