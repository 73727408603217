.cursor-pointer {
  cursor: pointer;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome and Safari*/
}

/* Hide scrollbar */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fa-spin {
  animation: fa-spin .7s infinite linear !important;
}

.show-on-hover-toggle:hover .show-on-hover {
  //opacity: 1;
  display: inline;
}

.show-on-hover {
  //opacity: 0;
  display: none;
}
