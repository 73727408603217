.set-zoom-control {
    height: 29px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.set-control-zoom-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='13pt' version='1.1' viewBox='-1 0 136 136.21852' width='136pt'%3E%3Cg id='surface1'%3E%3Cpath d='M 93.148438 80.832031 C 109.5 57.742188 104.03125 25.769531 80.941406 9.421875 C 57.851562 -6.925781 25.878906 -1.460938 9.53125 21.632812 C -6.816406 44.722656 -1.351562 76.691406 21.742188 93.039062 C 38.222656 104.707031 60.011719 105.605469 77.394531 95.339844 L 115.164062 132.882812 C 119.242188 137.175781 126.027344 137.347656 130.320312 133.269531 C 134.613281 129.195312 134.785156 122.410156 130.710938 118.117188 C 130.582031 117.980469 130.457031 117.855469 130.320312 117.726562 Z M 51.308594 84.332031 C 33.0625 84.335938 18.269531 69.554688 18.257812 51.308594 C 18.253906 33.0625 33.035156 18.269531 51.285156 18.261719 C 69.507812 18.253906 84.292969 33.011719 84.328125 51.234375 C 84.359375 69.484375 69.585938 84.300781 51.332031 84.332031 C 51.324219 84.332031 51.320312 84.332031 51.308594 84.332031 Z M 51.308594 84.332031 ' style=' stroke:none;fill-rule:nonzero;fill:rgb(0%25,0%25,0%25);fill-opacity:1;' /%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    height: 29px;
    width: 29px;
}

.set-zoom-control.closed {
    width: 29px;
}

.set-zoom-control.opened {
    width: 170px;
}

.set-control-zoom-input {
    width: 100px;
    height: 20px;
}

.set-control-zoom-input-box {
    display: flex;
    align-items: center;
}

.set-control-zoom-input {
    margin: 0 5px;
}

.closed {
    display: none;
}
