/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
@import 'mapbox-gl-style-switcher/styles.css';
@import '../../../libs/map/src/lib/set-zoom-plugin/set-zoom-plugin';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-balham.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import 'styles/sanitize.css';
@import 'libs/core/src/lib/ui/core-ui.scss';
@import 'styles/angular.material.theming.scss';
@import 'styles/angular.material.fix.css';
@import 'libs/map3-ui/src/lib/styles.scss';
//@import 'air-datepicker/air-datepicker.css';
