@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700;900&display=swap');
@import "@angular/cdk/overlay-prebuilt.css";
@import "+styles/menu.style.scss";

* {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

:root {
  // COLORS
  --primary-color: #1e4294;
  --primary-light-color: #e5ebff;
  --error-color: #d32f2f;
  --success-color: #4caf50;
  --warn-color: #ffc107;
  --border: #e3e3e3;
  --hover: #eeeeee;
  --grey: #a9a9a9;
  --bg-color: #ffffff;
  --text-color: #424242;

  // SPACING
  --spacing-1: 0.25em;
  --spacing-2: 0.5em;
  --spacing-3: 1em;
  --spacing-4: 2em;
  --spacing-5: 4em;
  // FONT SIZES
  --font-size-1: 0.75rem;
  --font-size-2: 0.95rem;
  --font-size-3: 1.15rem;
  --font-size-4: 1.5rem;
  --font-size-5: 3rem;
  // FONT WEIGHTS
  --font-weight-1: 200;
  --font-weight-2: 400;
  --font-weight-3: 700;
  --font-weight-4: 900;
  //shapes
  --radius-1: 5px;
  --radius-2: 10px;
}

html,
body {
  user-select: none;
  padding: 0 !important;
  margin: 0 !important;
  color: var(--text-color) !important;
  //font-size: var(--font-size-2);
  font-weight: var(--font-weight-2);
  height: 100%;
}

// TODO check where font rules are used

.title {
  font-size: 17px;
  line-height: 21px;
}

.title-bold {
  @extend .title;
  font-weight: 500;
}

.primary-text {
  font-size: 15px;
  line-height: 18px;
}

.primary-text-bold {
  @extend .primary-text;
  font-weight: 500;
}

.secondary-text {
  font-size: 11px;
  line-height: 13px;
}

.secondary-text-bold {
  @extend .secondary-text;
  font-weight: 500;
}

.shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.hover-1:hover {
  font-weight: bolder;
  cursor: pointer;
  color: var(--text-color);
}

.selectable-text {
    user-select: text;
  cursor: text;
}

input,
button,
textarea {
  height: 35px;
  margin: 0;
  padding: 5px 10px;
  border-radius: var(--radius-1);
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border: none;
  vertical-align: center;
}

input,
textarea {
  border: 1px solid #dbdbdb;
}

.btn-primary {
  color: #ffffff;
  background-color: var(--primary-color);
}

.btn-success {
  color: #ffffff;
  background-color: var(--success-color);
}

.error-label {
  color: var(--error-color);
}

input {
  //width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

textarea:focus,
input:focus {
  outline: none;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0 10px var(--border);
}

select,
.mat-select {
  background: none;
  border: 1px solid var(--border);
  border-radius: var(--radius-1);
  padding: 4px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  height: 35px;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/** SCROLLBAR **/

* {
  scrollbar-color: var(--primary-color) var(--border);
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background-color: var(--border);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  //background: #555;
}
